<template>
  <div class="transaction">
    <el-card class="box-card" shadow="never">
      <el-form slot="header" class="searchFrom" size="small" :inline="true" lable-width="80px"
               :model="searchForm">
        <el-form-item label="订单类型:">
          <el-select style="width: 80px;" v-model="searchForm.productType" placeholder="">
            <el-option
              v-for="item in condition.productTypes"
              :label="item.label" :value="item.value" :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号:">
          <el-input style="width: 150px;" v-model="searchForm.orderNo" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="下单时间:">
          <el-date-picker
            style="width: 280px;"
            v-model="searchForm.createTimeLimit"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-button size="small" @click="getOrder" type="primary">搜索</el-button>
        <el-button size="small" native-type="reset">重置</el-button>
      </el-form>
      <div class="operation-btn-group">
        <template v-for="operation in orderStatusOperation">
          <el-button
            @click="changeOrderStatus(operation.value)"
            plain size="mini" :key="operation.name"
            :class="['sctp-square',{active: activeOperation(operation.value)}]"
          >
            {{ operation.name }}
          </el-button>
        </template>
      </div>
      <div class="">
        <el-row class="sctp-pad15">
          <el-col :span="1">
            <el-checkbox @change="setAllStatus" :indeterminate="indeterminate" :value="selectAll">
              全选
            </el-checkbox>
          </el-col>
          <el-col :span="12">
            <div class="sctp-tc">店铺宝贝</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">单价</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">状态</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">数量</div>
          </el-col>
          <el-col :span="3">
            <div class="sctp-tc">小计</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">操作</div>
          </el-col>
        </el-row>
        <div style="min-height: 100px" v-loading="isLoadData">
          <template v-if="orderData && orderData.length > 0">
            <template v-for="order in orderData">
              <div class="order-item" :key="order.orderid">
                <div
                  class="sctp-flex sctp-mar-b10 sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
                  <div class="sctp-mar-r15">订单号：{{ order.ordernum }}</div>
                  <div class="sctp-mar-r15">提交时间：{{ dateToString(order.createtime) }}</div>
                  <div class="sctp-mar-r15">客户邮箱：{{ order.user.email }}</div>
                </div>
                <template v-if="order.user">
                  <div class="sctp-flex sctp-mar-tb10 sctp-pad-lr15 sctp-flex-aic">
                    <el-avatar
                      :size="20"
                      class="sctp-mar-r15"
                      :src="order.user.avatar"
                      fit="cover"
                    ></el-avatar>
                    <div>买家：{{ order.user.username }}</div>
                  </div>
                </template>
                <template v-for="goods in order.goods">
                  <div class="goods-list" :key="goods.goodsid">
                    <el-row type="flex" align="middle" class="sctp-pad15 goods-item">
                      <el-col :span="1">
                        <el-checkbox v-model="goods.selected"></el-checkbox>
                      </el-col>
                      <el-col :span="12">
                        <div class="">
                          <div class="sctp-flex">
                            <el-image
                              class="sctp-mar-r15"
                              style="width: 80px; height: 80px"
                              :src="goods.goodsimg"
                              fit="cover"></el-image>
                            <div class="sctp-flex-item-1">
                              <router-link target="_blank" :to="{
                                2: `/sourcecode/view/${goods.goodsid}`,
                                4: `/design/view/${goods.goodsid}`,
                                6: `/techdocs/view/${goods.goodsid}`
                              }[order.ordertype]">
                                <h3>
                                  {{ goods.goodsname }}
                                </h3>
                              </router-link>
                              <!--<div class="goods-introduce">-->
                              <!--  <div v-html="goods.introduce"></div>-->
                              <!--</div>-->
                            </div>
                          </div>
                          <div>
                            <template v-if="order.orderGoodsList[0].copyright_price != null">
                              <div class="pad-tb5">版权费用：{{
                                  order.orderGoodsList[0].copyright_price
                                }}￥
                              </div>
                            </template>
                            <template v-if="order.orderGoodsList[0].install_price != null">
                              <div class="pad-tb5">提供远程安装服务：{{
                                  order.orderGoodsList[0].install_price
                                }}￥
                              </div>
                            </template>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-tc">¥{{ goods.price }}</div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-tc">
                          <template v-if="order.refund && order.refund.status !== 2">
                            <template v-if="order.refund.status === 0">
                              退款申请中
                            </template>
                            <template v-if="order.refund.status === 1">
                              已同意退款
                            </template>
                            <template v-if="order.refund.status === 2">
                              已拒绝退款
                            </template>
                          </template>
                          <template v-else>
                            {{ getOrderStatus(order) }}
                          </template>
                          <template v-if="order.customerService">

                            <div class="fc-red" v-if="order.customerFlag">(售后完成)</div>
                            <div class="fc-red" v-else>(申请售后)</div>
                          </template>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-tc">
                          {{ goods.amount || 0 }}
                        </div>
                      </el-col>
                      <el-col :span="3">
                        <div class="sctp-color-main sctp-tc">¥{{
                            order.paymoney
                          }}
                          <i
                            v-if="order.state === 0"
                            @click="changeOrderPriceConfig.showDialog(order)"
                            class="el-icon-edit"></i>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-flex sctp-flex-column">
                          <template v-if="order.refund && order.refund.status !== 2">
                            <template v-if="order.refund.status === 0">
                              <div class="sctp-flex sctp-flex-center sctp-mar-b5">
                                <el-popover
                                  placement="top"
                                  title="退款理由"
                                  width="400"
                                  trigger="hover"
                                  :content="order.refund.reqReason">
                                  <div slot="reference">
                                    <div class="sctp-flex sctp-flex-center sctp-mar-b5">
                                      <el-button
                                        @click="orderOptions.onAgreeToRefundClick(order, goods)"
                                        size="mini"
                                      >
                                        同意退款
                                      </el-button>
                                    </div>
                                    <div class="sctp-flex sctp-flex-center sctp-mar-b5">
                                      <el-button
                                        type="warning"
                                        @click="orderOptions.onRefusalToRefundClick(order, goods)"
                                        size="mini"
                                      >
                                        拒绝退款
                                      </el-button>
                                    </div>
                                  </div>
                                </el-popover>
                              </div>
                            </template>
                          </template>
                          <template v-else-if="!order.refund || order.refund.status !== 1">
                            <template v-if="order.state === 2">
                              <div class="sctp-flex sctp-flex-center sctp-mar-b5">
                                <el-button
                                  type="primary"
                                  size="mini"
                                  @click="orderOptions.onConfirmDeliveryClick(order, goods)"
                                >
                                  确认发货
                                </el-button>
                              </div>
                            </template>
                          </template>
                          <template v-if="order.customerService">
                            <div class="sctp-mar-b5 sctp-tc">
                              <el-button
                                size="mini"
                                @click="orderOptions.onOptionsClick('saleAfter', order)"
                              >
                                售后服务
                              </el-button>
                            </div>
                          </template>
                          <div class="sctp-mar-b5 sctp-tc">
                            <el-button
                              @click="orderOptions.onOptionsClick('detail', order)"
                              size="mini"
                            >
                              订单详情
                            </el-button>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </template>
                <template v-if="order.deliver_way">
                  <div class="pad-tb10 pad-lr15">
                    <span>发货方式：{{ {1: '邮箱', 2: '物流'}[order.deliver_way] }}</span>
                    <template v-if="order.deliver_way === 2">
                      <span class="mg-l15">物流名称：{{ order.deliver_logistics }}</span>
                      <span class="mg-l15">物流单号：{{ order.logistics_order_no }}</span>
                    </template>
                    <template v-else-if="order.deliver_way === 1">
                      <span class="mg-l15">发货邮箱：{{ order.deliver_email }}</span>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="empty-text">暂无数据</div>
          </template>
        </div>
        <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
          <el-pagination
            :current-page="pageConfig.page"
            :page-size="pageConfig.limit"
            @size-change="pageConfig.handleSizeChange"
            @current-change="pageConfig.handleCurrentChange"
            :page-sizes="pageConfig.pageSizes"
            layout="prev, pager, next, total, sizes"
            :total="pageConfig.total">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog center width="500px" title="卖家确认发货" :visible.sync="confirmDeliveryConfig.show">
      <el-form
        style="width: 400px;margin: auto;"
        :model="confirmDeliveryConfig.data"
        :rules="confirmDeliveryConfig.rules"
        ref="confirmDeliveryForm"
      >
        <el-form-item label="发货方式：" prop="deliverWay">
          <el-radio-group @change="$refs['confirmDeliveryForm'].clearValidate()"
                          v-model="confirmDeliveryConfig.data.deliverWay">
            <el-radio :disabled="true" :label="1">邮箱</el-radio>
            <el-radio :disabled="true" :label="2">物流</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="confirmDeliveryConfig.data.deliverWay === 1">
          <el-form-item prop="deliverEmail">
            <el-input
              v-model="confirmDeliveryConfig.data.deliverEmail" autocomplete="off"
              placeholder="输入发货邮箱"></el-input>
          </el-form-item>
        </template>
        <template v-if="confirmDeliveryConfig.data.deliverWay === 2">
          <el-form-item prop="deliverLogistics">
            <el-select v-model="confirmDeliveryConfig.data.deliverLogistics" filterable
                       placeholder="请选择物流">
              <el-option
                v-for="item in confirmDeliveryConfig.condition.logistics"
                :key="item.id"
                :label="item.logisticsname"
                :value="item.logisticsname">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="logisticsOrderNo">
            <el-input
              v-model="confirmDeliveryConfig.data.logisticsOrderNo" autocomplete="off"
              placeholder="输入物流单号"></el-input>
          </el-form-item>
        </template>
        <el-form-item align="center">
          <el-button @click="confirmDeliveryConfig.show = false" size="small">取消</el-button>
          <el-button @click="confirmDeliveryConfig.onSubmitClick" size="small" type="primary">确认
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog center width="500px" title="卖家修改订单价格" :visible.sync="changeOrderPriceConfig.show">
      <el-form
        style="width: 400px;margin: auto;"
        :model="changeOrderPriceConfig.form"
        :rules="changeOrderPriceConfig.formRules"
        ref="changeOrderPriceForm"
      >
        <el-form-item label="订单金额" prop="price">
          <el-input v-model="changeOrderPriceConfig.form.price">
            <div slot="append">
              RMB (单位:元)
            </div>
          </el-input>
        </el-form-item>
        <el-form-item align="center">
          <el-button @click="changeOrderPriceConfig.show = false" size="small">取消</el-button>
          <el-button @click="changeOrderPriceConfig.onSubmitClick" size="small" type="primary">确认
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {order} from '@/apis/index'
import validator from "@/resources/js/async-validator";

export default {
  name: 'shopTransaction',
  components: {},
  data() {
    return {
      isLoadData: false,
      searchForm: {
        orderNo: null,
        createTimeLimit: null,
        productType: null,
        status: null,
      },
      condition: {
        productTypes: [
          {label: '全部', value: null},
          {label: '源码', value: 'SOURCECODE'},
          {label: '设计', value: 'DESIGN'},
          {label: '文档', value: 'DOCUMENT'},
        ]
      },
      pageConfig: {
        page: 1,
        limit: 10,
        total: 0,
        pageSizes: [1, 2, 5, 10].map(item => item * 10),
        handleSizeChange: (limit) => {
          this.pageConfig.page = 1;
          this.pageConfig.limit = limit;
          this.getOrder()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.getOrder()
        }
      },
      activeOrderId: null,
      confirmDeliveryConfig: {
        onSubmitClick: () => {
          this.$refs['confirmDeliveryForm'].validate()
          .then(res => {
            let {deliverWay, deliverEmail, deliverLogistics, logisticsOrderNo} = this.confirmDeliveryConfig.data;
            let {activeOrderId} = this;
            this.orderOptions.doConfirmDelivery({
              orderId: activeOrderId,
              deliverWay,
              deliverEmail,
              deliverLogistics,
              logisticsOrderNo
            }).then(res => {
              this.confirmDeliveryConfig.show = false;
            });
          });
        },
        loadCondition: () => {
          order.loadLogistics().then(res => {
            let {logistics = []} = res.retdata;
            this.confirmDeliveryConfig.condition.logistics = logistics;
          })
        },
        condition: {
          logistics: [],
        },
        data: {
          deliverWay: null,
          deliverEmail: null,
          deliverLogistics: null,
          logisticsOrderNo: null,
        },
        rules: {
          deliverWay: [
            {required: true, message: '请选择发货方式', trigger: ['change', 'blur']},
          ],
          deliverEmail: [
            {required: true, message: '请输入邮箱', trigger: ['change', 'blur']},
            {validator: validator.validEmail, message: '请输入正确邮箱', trigger: ['change', 'blur']},
          ],
          deliverLogistics: [
            {required: true, message: '请选择物流', trigger: ['change', 'blur']},
          ],
          logisticsOrderNo: [
            {required: true, message: '请输入物流单号', trigger: ['change', 'blur']},
          ],
        },
        show: false,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      orderData: [],
      orderOptions: {
        onOptionsClick: (option, order) => {
          let {orderid: orderId} = order;
          if (this.orderOptions.options[option]) {
            this.orderOptions.options[option].call(null, {orderId: orderId});
          }
        },
        options: {
          detail: ({orderId}) => {
            this.openPage(`/order/orderDetail4Shop/${orderId}`);
          },
          saleAfter: ({orderId}) => {
            this.$router.push({
              path: `/order/salesAfter/${orderId}`
            })
          },
        },
        onAgreeToRefundClick: (order, goods) => {
          this.activeOrderId = order.orderid;
          this.$confirm('确认同意退款', '提示').then(() => {
            this.orderOptions.doAgreeToRefund(order, goods);
          });
        },
        doAgreeToRefund: (item, product) => {
          this.activeOrderId = item.orderid;
          return order.agreeToRefund({
            orderId: item.orderid
          }).then(res => {
            this.$message.success('操作成功');
            this.getOrder();
          });
        },
        onRefusalToRefundClick: (order, goods) => {
          this.activeOrderId = order.orderid;
          this.$confirm('确认拒绝退款', '提示').then(() => {
            this.$prompt('请输入拒绝理由', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
              // inputErrorMessage: ''
            }).then(({value}) => {
              this.orderOptions.doRefusalToRefund(order, goods, value);
            })
          });
        },
        doRefusalToRefund: (item, product, reason) => {
          this.activeOrderId = item.orderid;
          order.refusalToRefund({
            orderId: item.orderid,
            rejectReason: reason,
          }).then(res => {
            this.$message.success('操作成功');
            this.getOrder();
          });
        },
        onConfirmDeliveryClick: (item, product) => {
          this.activeOrderId = item.orderid;
          let deliverWay = product.saletype;
          switch (deliverWay) {
            case 1:
              this.confirmDeliveryConfig.data.deliverWay = 1;
              break;
            case 4:
              this.confirmDeliveryConfig.data.deliverWay = 2;
              break;
          }
          this.confirmDeliveryConfig.show = true;
        },
        doConfirmDelivery: ({orderId, deliverWay, deliverEmail, deliverLogistics, logisticsOrderNo}) => {
          return order.confirmDelivery({
            orderId: orderId,
            deliverWay,
            deliverEmail,
            deliverLogistics,
            logisticsOrderNo,
          }).then(res => {
            this.$message.success('操作成功');
            this.getOrder();
          });
        },
      },
      changeOrderPriceConfig: {
        show: false,
        showDialog: (order) => {
          this.changeOrderPriceConfig.form.orderId = order.orderid;
          this.changeOrderPriceConfig.show = true;
        },
        form: {
          price: null,
          orderId: null,
        },
        formRules: {
          price: [
            {required: true, message: '价格不能为空', trigger: ['blur', 'change']},
            {validator: validator.validMoney, message: '金额数值不合法', trigger: ['blur', 'change']},
          ]
        },
        onSubmitClick: () => {
          this.$refs.changeOrderPriceForm.validate()
          .then(res => {
            let {price, orderId} = this.changeOrderPriceConfig.form;
            order.changeOrderPrice({
              price, orderId,
              userId: this.user.userId,
            }).then(res => {
              this.getOrder();
              this.changeOrderPriceConfig.show = false;
            })
          })
        }
      },
    }
  },
  methods: {

    changeOrderStatus(status) {
      this.searchForm.status = status;
      this.page = 1;
      this.getOrder()
    },
    setAllStatus(flag) {
      if (this.orderData) {
        this.orderData.forEach(order => {
          if (order.goods) {
            order.goods.forEach(goods => {
              goods.selected = !!flag
            })
          }
        })
      }
    },
    getOrder() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      if (searchForm.createTimeLimit) {
        searchForm.createStart = this.dateToString(searchForm.createTimeLimit[0],
          'yyyy-MM-dd 00:00:00')
        searchForm.createEnd = this.dateToString(searchForm.createTimeLimit[1],
          'yyyy-MM-dd 23:59:59')
        delete searchForm.createTimeLimit
      }
      this.isLoadData = true;
      order.transaction({
        userId: this.user.userId,
        page: this.pageConfig.page,
        limit: this.pageConfig.limit,
        status: searchForm.status,
        createEnd: searchForm.createEnd,
        createStart: searchForm.createStart,
        orderNo: searchForm.orderNo,
        productType: searchForm.productType,
      }).then(res => {
        const {retdata = [], count = 0} = res
        this.orderData = retdata;
        this.pageConfig.total = count;
      }).finally(() => {
        this.isLoadData = false;
      });
    },
  },
  computed: {
    orderStatusOperation() {
      return [
        {name: '全部', value: null},
        {name: '待支付', value: 0},
        {name: '已取消', value: 1},
        {name: '待发货', value: 2},
        {name: '待收货', value: 3},
        {name: '待评价', value: 4},
        {name: '已完成', value: 5},
      ]
    },
    activeOperation() {
      return (value) => {
        return value === this.searchForm.status
      }
    },
    selectAll() {
      let flag = false
      if (this.orderData && this.orderData.length > 0) {
        flag = this.orderData.every(order => {
          if (order.goods && order.goods.length > 0) {
            return order.goods.every(goods => {
              return goods.selected
            })
          }
        })
      }
      return flag
    },
    indeterminate() {
      if (this.selectAll) {
        return false
      } else {
        let flag = false
        if (this.orderData) {
          flag = this.orderData.some(order => {
            if (order.goods && order.goods.length > 0) {
              return order.goods.some(goods => {
                return goods.selected
              })
            }
          })
        }
        return flag
      }
    },
    getOrderStatus() {
      return (order) => {
        let {state} = order
        let ret = '';
        switch (state) {
          case 0:
            ret = '待支付';
            break;
          case 1:
            ret = '已取消';
            break;
          case 2:
            ret = '待发货';
            break;
          case 3:
            ret = '待收货';
            break;
          case 4:
            ret = '待评价';
            break;
          case 5:
            ret = '已完成';
            break;
        }
        if (state === 4 || state === 5) {
          if (order.isTransfer) {
            ret += ',平台已打款'
          } else {
            ret += ',待平台打款'
          }
        }
        return ret;
      }
    },
  },
  beforeMount() {

    this.getOrder();
    this.confirmDeliveryConfig.loadCondition();
  },
}
</script>

<style scoped lang="scss">
.transaction {
  ::v-deep {
    .goods-introduce * {
      color: #909399;
    }

    .el-col {
      .el-icon-edit {
        display: none;
        cursor: pointer;
      }

      :hover .el-icon-edit {
        display: inline-block;
      }
    }
  }

  .order-item {
    padding-bottom: 15px;
    border-width: 1px;
    border-color: #e5e5e5;
    border-style: solid;
    margin: 15px 0;
  }

  .el-button.active {
    color: #F90 !important;
    border-color: #F90 !important;
  }

}
</style>
